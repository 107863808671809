import React from "react";
import { withRouter } from "react-router-dom";
import NavbarBootstrap from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import NavbarButton from "./buttons/NavbarButton";
import logo from "../../img/hashworth_logo.PNG";
import Button from "react-bootstrap/Button";
import flag from "../../img/flag.jpg";

import "./Navbar.css";

//Navigation bar that enables users to login, logout, view their profile, and view the admin page if user is an admin
function Navbar(props) {
    return (
        <NavbarBootstrap className="navbar pr-0" expand="xl" sticky="top">
            <NavbarBootstrap.Brand className="pr-5 mr-0" href="/home">
                <img src={logo} className="logo"/>
            </NavbarBootstrap.Brand>
            <NavbarBootstrap.Toggle className="hamburgerIcon" aria-controls="responsive-navbar-nav" />
            <NavbarBootstrap.Collapse className="justify-content-end" id="responsive-navbar-nav">
                <Nav className="hamburgerMenu">
                <img src={flag} className="flag"/>
                    <Nav.Link href="/about">
                        <NavbarButton selectedButton={props.page === "about" ? "selectedButton" : ""}>About</NavbarButton>
                    </Nav.Link>
                    <Nav.Link href="/services">
                        <NavbarButton selectedButton={props.page === "services" ? "selectedButton" : ""}>Services</NavbarButton>
                    </Nav.Link>
                    <Nav.Link href="/adolescents">
                        <NavbarButton selectedButton={props.page === "adolescents" ? "selectedButton" : ""}>Adolescents</NavbarButton>
                    </Nav.Link>
                    <Nav.Link href="/studies">
                        <NavbarButton selectedButton={props.page === "studies" ? "selectedButton" : ""}>Research</NavbarButton>
                    </Nav.Link>
                    <Nav.Link href="/faqs">
                        <NavbarButton selectedButton={props.page === "faqs" ? "selectedButton" : ""}>FAQs</NavbarButton>
                    </Nav.Link>
                    <Nav.Link href="/hiring">
                        <NavbarButton selectedButton={props.page === "hiring" ? "selectedButton" : ""}>Join Our Team</NavbarButton>
                    </Nav.Link>
                    <Nav.Link href="/appointments">
                        <Button className="clickableBtn navbarButton" size="lg" variant="primary">
                            Book an Appointment
                        </Button>
                    </Nav.Link>
                </Nav>
            </NavbarBootstrap.Collapse>
        </NavbarBootstrap>
    );
}

export default withRouter(Navbar);