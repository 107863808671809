import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Adolescents from "./components/adolescents/Adolescents";
import Home from "./components/home/Home";
import LandingPage from "./components/landingPage/LandingPage"
import Services from "./components/services/Services";
import About from "./components/about/About";
import MissingPage from "./components/missingPage/MissingPage";
import Bookings from "./components/bookings/Bookings";
import Faqs from "./components/faqs/FAQs";
import Hiring from "./components/hiring/Hiring";
//import Preview from "./components/preview/Preview";

import './App.css';
import Research from "./components/research/Research";
import Enquiries from "./components/enquiries/Enquiries";

function App() {
  // const location = window.location;
  // if (location.protocol !== 'https:') {
  //   location.replace(`https:${location.href.substring(location.protocol.length)}`);
  // }
  //Website routing
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage}/>
        <Route path="/home" component={Home}/>
        <Route path="/about" component={About}/>
        <Route path="/services" component={Services}/>
        <Route path="/adolescents" component={Adolescents}/>
        <Route path="/appointments" component={Bookings}/>
        <Route path="/faqs" component={Faqs}/>
        <Route path="/studies" component={Research}/>
        <Route path="/enquiries" component={Enquiries}/>
        <Route path="/hiring" component={Hiring}/>
        <Route component={MissingPage}/>
      </Switch>
    </Router>
  );

  // return (
  //   <Router>
  //     <Switch>
  //       <Route>
  //         <Preview/>
  //       </Route>
  //     </Switch>
  //   </Router>
  // );
}

export default App;
