export default {
    title: "Frequently Asked Questions (FAQ)",
    subtitle: "Got questions?",
    description: "Click on the questions below to learn more.",
    questions: [
        {
            question: 'What is the cost of a therapy session?',
            answer: `
                <p>If you choose to start or continue your therapeutic journey with Hashworth Psychology Pty Ltd, the fee will depend on which clinician you see, their qualifications, and whether you have a valid referral and Mental Health Treatment Plan.</p>
                <p>Check out the "Book an Appointment" tab for an overview of all fees.</p>
                <h4>Clinical Psychologists</h4></br>
                <p>Initial Consultation (50 minutes): $255-270</p>
                <p>Standard Session (50 minutes): $245-255</p>
                <p>Extended EMDR Session (90 minutes): $441-459</p></strong>
               
                <h4>Registered Psychologists</h4></br>
                <p>Initial Consultation (50 minutes): $209</p>
                <p>Standard Session (50 minutes): $199</p>
                <p>Extended EMDR Session (90 minutes): $358.20</p></strong>
            
                <h4>Counsellors and Provisional Psychologists</h4></br>
                <p>Initial Consultation (50 minutes): $85-135</p>
                <p>Standard Session (50 minutes): $85-135</p>
                <p>Extended EMDR Session (90 minutes): $153-243</p>
               
                <p>Medicare rebates cannot be claimed for provisional psychologists or counsellors, these clinicians have provided reduced fees and out-of-hours availability.</p>
            `
        },
        {
            question: 'Do you offer Telehealth appointments?',
            answer: `
                <p>Yes! In addition to in-person appointments, Hashworth Psychology Pty Ltd offers Telehealth appointments either through a videoconferencing platform or via phone call.</p>
                <p>If you prefer Telehealth appointments, you will need access to a quiet, private space, and an appropriate device, i.e. smartphone, laptop, iPad, computer, with a camera, microphone and speakers, and a reliable broadband internet connection.</p>
                <p>The privacy of any form of communication via the internet is potentially vulnerable and limited by the security of the technology used. To support the security of your personal information, Hashworth Psychology Pty Ltd uses Zoom and Doxy.me which is compliant with the Australian standards for online security and encryption.</p>
            `
        },
        {
            question: 'What types of therapy do you offer?',
            answer: `
                <p>We use a range of validated and evidence-based approaches, including, but not limited to:</p>
                <ul>
                    <li>Eye Movement Desensitisation Reprocessing (EMDR) Therapy</li>
                    <li>Dialectical Behavioural Therapy (DBT)</li>
                    <li>Schema Therapy</li>
                    <li>Cognitive Behavioural Therapy (CBT)</li>
                    <li>Trauma-Focused CBT</li>
                    <li>Acceptance & Commitment Therapy (ACT)</li>
                    <li>Motivational Interviewing (MI)</li>
                    <li>Feedback Informed Treatment (FIT)</li>
                    <li>Exposure Therapy and Response Prevention (ERP)</li>
                    <li>Family Therapy</li>
                </ul>
                <p>You and your clinician will collaborate to determine the best treatment approach for you, tailoring therapy to your individual needs.</p>
            `
        },
        {
            question: 'I am in crisis. What do I do?',
            answer: `
                <p>If you are in crisis and feel as if you may harm yourself or someone else, please reach out to the following supports:</p>
                <ul>
                    <li>Emergency Services (000)</li>
                    <li>Lifeline Australia (13 11 14)</li>
                    <li>Kids Helpline (1800 55 1800)</li>
                    <li>The NSW Mental Health Line (1800 011 511)</li>
                    <li>Your local Emergency Department</li>
                </ul>
                <p>Due to the nature of private practice, Hashworth Psychology Pty Ltd is not a crisis service. We are unable to provide immediate support outside of business hours and/or without an appointment. Please contact the above services for support if you find yourself in crisis or do not feel safe.</p>
            `
        },
        {
            question: 'Do I need a referral to see a psychologist?',
            answer: `
                <p>No, you don’t need a referral to see a psychologist. However, if you’d like to claim a Medicare rebate, you will need to visit your GP to obtain a Mental Health Treatment Plan.</p>
                <p>At Hashworth Psychology, we have a multidisciplinary team, including provisional psychologists, counsellors, and social workers, offering cost-effective sessions that do not require a referral.</p>
                <p>We recommend contacting our admin team to discuss your needs and help match you with the most suitable clinician. Once matched, we’ll let you know if a referral and Mental Health Treatment Plan is necessary for the clinician you’ve booked with.</p>
            `
        },
        {
            question: 'Can I get a rebate through Medicare or private health insurance?',
            answer: `
            <p>Yes, if you’re booked to see a registered or clinical psychologist. As long as you have a valid Mental Health Treatment Plan from your GP and a valid Medicare card, you are entitled to a Medicare rebate for up to 10 sessions per calendar year.</p>
            <p>For a 50-minute session with a registered psychologist, you may claim a Medicare rebate of $96.65 per session. For a 50-minute session with a clinical psychologist, you may claim a Medicare rebate of $141.85 per session.</p>
            <p>You do not need to come through Medicare to see a psychologist at Hashworth Psychology Pty Ltd if you choose not to. In this case, you would be able to attend as many sessions as you would like but would not be able to get a Medicare rebate.</p>
            <p>Provisional psychologists and counsellors are unable to provide Medicare rebates; however, you may enquire with your private health insurance to see if you are eligible for any rebates. Please contact your health fund directly to enquire about the level of coverage that you can receive.</p>
            <p>Please note, you cannot claim both a Medicare rebate and a private health fund rebate at the same time.</p>
            `
        },
        {
            question: 'How long are sessions with a psychologist?',
            answer: `
                <p>At Hashworth Psychology Pty Ltd, your appointments are generally 50 minutes long.</p> 
                <p>If preferred, you and your psychologist can discuss the option of having 90 minute sessions to allow for deeper and more intensive therapeutic processing.</p>           
            `
        },
        {
            question: 'How do I book an appointment?',
            answer: `
                <p>Booking an appointment at Hashworth Psychology Pty Ltd is super easy. If you're a first-time or existing client, please call us on <a href="tel:0491-683-910">0491 683 910</a> or email us at <a href="mailto:connect@hashworthpsychology.com.au">connect@hashworthpsychology.com.au</a> to discuss your current situation and your preferred appointment time(s). If you have any specific questions or concerns, we’d be happy to support you in determining if Hashworth Psychology Pty Ltd is the best fit for you.</p>
            `
        },
        {
            question: 'How do I know if Hashworth Psychology Pty Ltd is the right fit for me?',
            answer: `
                <p>This is a really important question. Finding a psychologist who you can connect with, feel safe with, and successfully work with can be tricky. At Hashworth Psychology Pty Ltd, we do our very best to be the right person for you, but we know it’s not always as simple as that. If you’re unsure, we suggest taking a look through our website, specifically the biography, fees, therapeutic approaches, and the primary mental health concerns that we work with. We also invite you to schedule an initial consultation to better discuss any questions or concerns that you may have and assess if we’re the right fit for you. There is no obligation to continue working with us if you choose not to.</p>
                <p>If you feel like we aren’t the right fit for you, we’re happy to recommend some other psychologists who may be a better option. If you feel like we are the right fit for you, we’d be happy to assist you in achieving your therapy goals.</p>                
            `
        },
        {
            question: 'What is your cancellation policy?',
            answer: `
                <p>As recommended by the Australian Psychological Society, if you need to cancel your scheduled appointment within <strong><u>less than 48 business hours notice, you will be charged a cancellation fee. The exact fee will be determined based on the amount of notice provided:</u></strong></p>
                <ul>
                    <li>Cancellations made within 0-24 business hours notice will incur the full session fee</li>
                    <li>Cancellations made within 25-48 business hours notice will incur 50% of the full session fee</li>
                </ul>
                <p>To avoid a fee, cancellations must be made with more than 48 hours notice and made within standard business hours (Monday to Friday from 9am to 5pm).</p>
            `
        },
        {
            question: 'When can I see someone?',
            answer: `
                <p>We are available every day of the week, including evenings and weekends. Our clinicians are able to see you with minimal wait periods and are able to work around your availability. Please reach out via phone (<a href="tel:0491-683-910">0491 683 910</a>) or email (<a href="mailto:connect@hashworthpsychology.com.au">connect@hashworthpsychology.com.au</a>) to book an appointment with your preferred clinician.</p>
            `
        },
        {
            question: 'Do you do ADHD and autism assessments?',
            answer: `
                <p>Yes! We have trained psychologists who conduct comprehensive ADHD and autism assessments.</p>
                <p>Below is some more information:</p>
                <p>ADHD Assessments:</p>
                <ul>
                    <li>4.5 hours of testing (including feedback session)</li>
                    <li>4-5 hours of interpretation and report writing</li>
                    <li>On some occasions, additional testing may be warranted which may increase the time requirement)</li>
                    <li>Payment plans are available and payment is NOT required in full prior to commencing the assessment</li>
                    <li>Fees for NDIS clients differ based on the NDIS Pricing Arrangements and Price Limits Guide</li>
                    <li>Following the assessment, you will have a one on one opportunity to learn about your results, diagnoses, and recommendations from your psychologist</li>
                </ul>
                <p>Autism Assessments:</p>
                <ul>
                    <li>4 hours of testing (including feedback session)</li>
                    <li>4 hours of interpretation and report writing</li>
                    <li>On some occasions, additional testing may be warranted which may increase the time requirement</li>
                    <li>Payment plans are available and payment is NOT required in full prior to commencing the assessment</li>
                    <li>Fees for NDIS clients differ based on the NDIS Pricing Arrangements and Price Limits Guide</li>
                    <li>Following the assessment, you will have a one on one opportunity to learn about your results, diagnoses, and recommendations from your psychologist</li>
                </ul>
                <p>NOTE: If additional testing is warranted for either your ADHD or autism assessment, your psychologist will discuss this with you directly. You will have the opportunity to consent to or decline any extra procedures which may incur additional costs.</p>
            `
        },
        {
            question: 'What is your COVID-19 Policy?',
            answer: `
                <p>Hashworth Psychology Pty Ltd is a COVID Safe practice, meaning we pride ourselves on the measures that we take to protect our clients, families, and staff. Due to requirements from NSW Health and the Australian Psychological Society, we are only able to conduct face to face sessions with clients who have been fully vaccinated. If you are not fully vaccinated or choose not to disclose your vaccination status, we can offer you Telehealth appointments or refer you to a GP. If family members, carers, or partners are required to attend sessions with you, we are obligated to ask for their vaccination status. You do not need to disclose the vaccination status of family members who do not plan to attend face to face sessions with you. Please note, we may request proof of vaccinations prior to your first face to face appointment.</p>
            `
        },
        {
            question: 'How do I choose the right clinician for me?',
            answer: `
                <p>Choosing the right clinician is an important step in starting your mental health journey.</p>
                <p>Here’s how we can help:</p>
                <ol>
                    <li>
                        <p>Contact Our Admin Team<p>
                        <p>If you’re interested in booking an appointment, our friendly admin team is here to assist. They can provide detailed information about which clinicians are available and which would be the best fit for your specific needs. Get in touch via email (<a href="mailto:connect@hashworthpsychology.com.au">connect@hashworthpsychology.com.au</a>) or call or text at <a href="tel:0491-683-910">0491 683 910</a></p>
                    </li>
                    <li>
                        <p>Consider Key Factors</p>
                        <p>Each clinician has unique areas of interest, availability, and specific ages and presentations they work with. Additionally, some clinicians can accept specific funding options (e.g., NDIS, Medicare, WorkCover), so it’s important to discuss these details with the admin team to ensure you’re matched with the most suitable clinician.</p>
                    </li>
                    <li>
                        <p>Next Steps After Booking</p>
                        <p>Once your booking is confirmed, our admin team will send you a quick online intake form to complete prior to your appointment. They will also discuss optional steps such as obtain a referral from your GP, if this is relevant to your chosen clinician. Our team will guide you through this process to make it as seamless as possible.</p>
                    </li>
                </ol>
                <p>If you’re unsure about where to start, don’t hesitate to reach out to us. We’re here to ensure you feel supported every step of the way.</p>
            `
        },
    ]
}