import logo from "../../img/preview-bg.jpg";

export default {
    title: "Our Services",
    subtitle: "How we can help you",
    description: "Click on each of the tiles below to learn more about the mental health areas that Hashworth Psychology Pty Ltd can support you with",
    services: [
        {
            title: "Borderline Personality Disorder",
            description: `
                <p>Often people diagnosed with borderline personality disorder (BPD) or those who have traits and symptoms of BPD describe their experience as a “rollercoaster”. What they mean by this is the feeling that their relationships, emotions, and sense of self are unstable and wavering. Sometimes, people express that they are unsure of who they actually are and what they like and dislike, and find themselves having difficulty understanding why they feel the way they do.</p>
                <p>People diagnosed with BPD may report:</p>
                <ul>
                    <li>Going out of their way to avoid abandonment by others</li>
                    <li>Unstable and intense interpersonal relationships</li>
                    <li>Uncertainties and confusions around their identity</li>
                    <li>Patterns of impulsivity</li>
                    <li>Suicidality, self-harming, or self-defeating behaviours</li>
                    <li>Difficulties regulating and managing their mood and emotions (including quick shifts and changes in moods)</li>
                    <li>Chronic feelings of emptiness (or a sense of feeling “nothing” and feeling shut down)</li>
                    <li>Intense bursts of anger</li>
                    <li>Paranoia towards others</li>
                    <li>Dissociation (or finding themselves in “auto-pilot” mode)</li>
                </ul>
            `,
            img: logo
        },
        {
            title: "Trauma and Complex Trauma",
            description: `
                <p>Trauma symptoms, post traumatic stress disorder (PTSD), or complex post traumatic stress disorder (C-PTSD) are terms used to describe the response to experiencing or witnessing a significantly traumatic event. On occasion, there may be a consensus on what is and what is not considered a traumatic event; however, if the individual found it traumatic, then it is and was traumatic.</p>
                <p>Some examples of traumatic events may include directly or indirectly being part of:</p>
                <ul>
                    <li>Domestic or family violence</li>
                    <li>Community violence</li>
                    <li>Neglect from caregivers </li>
                    <li>Verbal abuse </li>
                    <li>Physical abuse </li>
                    <li>Sexual abuse</li>
                    <li>Natural disasters </li>
                    <li>Car accidents </li>
                    <li>The death or loss of a significant person (including miscarriage)</li>
                    <li>Physical or emotional injuries </li>
                    <li>Surgeries or illnesses </li>
                    <li>War or political violence </li>
                    <li>Bullying </li>
                    <li>Interpersonal conflict</li>
                    <li>Infidelity </li>
                    <li>Legal troubles</li>
                </ul>
                <p>PTSD may emerge after a single or specific traumatic event, regardless of how big or small this may be. C-PTSD occurs from repeated or prolonged exposure to traumatic event(s).</p>
                <p>Symptoms and signs of both PTSD and C-PTSD include:</p>
                <ul>
                    <li>Nightmares or recurrent distressing dreams</li>
                    <li>Recurrent, involuntary, and intrusive distressing memories of the traumatic event(s)</li>
                    <li>Flashbacks or other dissociative reactions where individuals feel as if the traumatic event(s) are recurring again</li>
                    <li>Negative beliefs about oneself and the world (for example, “I am bad” or “people can’t be trusted”)</li>
                    <li>Psychological distress when presented with internal or external cues that resemble aspects of the trauma</li>
                    <li>Attempts to avoid distressing memories, thoughts, feelings, or external reminders (people, places, conversations, situations) related to the traumatic event(s)</li>
                    <li>Difficulty remembering specific aspects of the trauma</li>
                    <li>Excessive self-blame or blaming of others</li>
                    <li>Persistent negative emotional states (for example, fear, horror, anger, guilt, or shame)</li>
                    <li>Reduced interest or pleasure in activities</li>
                    <li>Feelings of detachment or disconnection from others</li>
                    <li>Difficulties experiencing positive emotions</li>
                    <li>Hypervigilance or paranoia</li>
                    <li>Difficulties with concentration</li>
                    <li>Sleep disturbances</li>
                    <li>Difficulty regulating emotions and impulses</li>
                    <li>Chronic physical health problems</li>
                </ul>
            `,
            img: logo
        },
        {
        title: "LGBTQIA+ Health",
            description: `
            <p>Learning and understanding yourself as someone who is different than the typical heterosexual and cisgender person can have its difficulties. Though there have been large shifts and gains in the world regarding gender and sexual identity and expression, LGBT+ folks still tend to experience many struggles. For some, these difficulties may be internal by questioning what is “wrong” with us or why we are different, or external, including prejudice, discrimination, or rejection from others. </p>
            <p>According to a 2020 report by the LGBTI National LGBTI Health Alliance (Snapshot of Mental Health and Suicide Prevention Statistics for LGBTI People), as well as other evidence-based research, LGBT+ individuals experience a disproportionate number of mental health difficulties, compared to their heterosexual and cisgender peers. Below are some important statistics: </p>
            <ul>
                <li>27.8% of bisexual people aged 18 and over reported attempting suicide</li>
                <li>48.1% of transgender and gender diverse people aged 14 to 25 reported attempting suicide</li>
                <li>Lesbian, gay, and bisexual people are twice as likely to be diagnosed and treated for mental health disorders, compared to their peers</li>
                <li>41.4% of lesbian, gay, and bisexual people aged 16 and over meet criteria for a mental disorder</li>
                <li>Transgender people aged 25 and under are 10 times more likely to be diagnosed with depression in their lifetimes</li>
                <li>Lesbian, gay, and bisexual people are three and a half times more likely to be diagnosed with anxiety, and transgender people are 10 to 13 times more likely</li>
                <li>44% of lesbian women reported thoughts about suicide, 20% have attempted suicide, and 46% reported self-harming</li>
                <li>46% of gay men reported thoughts about suicide, 14% have attempted suicide, and 21% reported self-harming</li>
                <li>29% of bisexual women reported thoughts about suicide, 10% have attempted suicide, and 30% reported self-harming</li>
                <li>32% of bisexual men reported thoughts about suicide, 9% have attempted suicide, and 17% reported self-harming</li>
            </ul>
            <p>These statistics are just a glance at some of the reasons why mental health support can make a difference for LGBT+ people. You may identify as LGBT+ and not have any current issues or difficulties related to your identity or your expression. You may identify as LGBT+ and face internal or external difficulties related to your identity or your expression. You may also not identify with the LGBT+ community but feel as if you are different from others. Regardless, Hashworth Psychology Pty Ltd provides a safe space where you can be who you are. </p>            
            `,
            img: logo
        },
        {
            title: "Group Therapy: Dialectical Behaviour Therapy",
            description: `
                <p>Hashworth Psychology Pty Ltd regularly run Dialectical Behaviour Therapy (DBT) group therapy programs. This program runs for either 5 consecutive weeks or 10 consecutive weeks for both adolescents and adults, with or without Borderline Personality Disorder (BPD). This program is cost-effective and Medicare rebates may apply. Please contact us to be added to our waitlist for the next intake.</p>
                <p>DBT is a therapeutic modality developed by Marsha Linehan that is used to help treat people with BPD, borderline traits, and those who find it difficult to manage their emotions and behaviours. DBT aims to replace problem behaviours with helpful behaviours, while focusing on mindfulness, distress tolerance, emotional regulation, interpersonal effectiveness. In addition, DBT is a skills-based program to allow individuals to learn how to reduce their destructive patterns and instead learn to tolerate and cope with intense feelings. Scientific research suggests that DBT produces notable results for individuals with BPD symptoms and can reduce and prevent suicide attempts and self-harming behaviours and symptoms of depression (Linehan et al., 2006; Turner, 2000; Cristea et al., 2017).</p>
            `,
            img: logo
        },
        {
            title: "Group Therapy: Unmasked",
            description: `
                <p>'Unmasked' is a 5-week therapeutic group program for late-diagnosed autistic adults and ADHDers (aged 16+), developed and led by practitioners with lived experience of ADHD and Autism. This program provides a safe and supportive space to learn about unmasking and supporting needs as a late-diagnosed adult, whether clients have been recently diagnosed or self-identify as Autistic and/or ADHD.</p>
                <p><u>What to Expect:</u> Over the course of the program, participants learn practical strategies to manage sensory sensitivities, enhance executive functioning skills, navigate social situations, advocate for themselves, and prevent burnout. Each session is designed to foster self-compassion, promote unmasking, and help develop meaningful connections within a like-minded community. The focus will be on neurodivergent-affirming practices, interactive activities, and accommodations tailored to participants' needs.</p>
                <p><u>Program Details:</u></p>
                <p>Duration: 5 weeks, with weekly 2-hour sessions (including breaks)</p>
                <p>Location: Hashworth Psychology, 1A Young Street, Wollongong</p>
                <p>Topics Covered:</p>
                <ul>
                    <li>Understanding ADHD and Autism</li>
                    <li>Managing Sensory Sensitivities</li>
                    <li>Building Executive Functioning Skills</li>
                    <li>Navigating Social Situations and Advocacy</li>
                    <li>Preventing Burnout and Fostering Self-Compassion</li>
                </ul>
                <p>Who Can Join: This program is open to all adults aged 16+ who identify as autistic or ADHD, regardless of clinical diagnosis.</p>
            `, 
            img: logo
        },
        {
            title: "Gender-affirming WPATH Assessments",
            description: `
                <p>At Hashworth Psychology, we are here to guide and support you on your journey toward gender-affirming care. Our WPATH assessments follow the World Professional Association for Transgender Health (WPATH) Standards of Care, designed to help individuals access hormone therapy, surgeries, and other medical interventions.</p>
                <p>We take a compassionate, affirming approach, working collaboratively with you to understand your unique experiences and goals. Our assessments focus on exploring your gender identity, mental health, and readiness for the desired interventions, always prioritising informed consent and your autonomy.</p>
                <p>We celebrate the diversity of gender experiences and are committed to creating a safe, supportive space for transgender, non-binary, gender-diverse, and cisgender individuals. If you’re needing gender-affirming care, our team is here to support you every step of the way. For more information or to book an appointment, please contact us.</p>
            `,
            img: logo
        },
        {
            title: "Neuro-Affirming Assessments: ADHD and Functional",
            description: `
                <p>What is a cognitive assessment?</p>
                <p>Psychological assessments involve the collecting and analysing of information relating to individuals’ behaviours, abilities and characteristics. This aims to target the diverse needs of clients, ruling out possible diagnosis, establishing possible diagnosis and using this as a tool to tailor therapy needs. Assessments may be designed according to your individualised goals and queries, to better understand how your brain works.</p>
                <p>What is an ADHD Assessment?</p>
                <p>Attention Deficit Hyperactivity Disorder (ADHD) is one of the most common forms of neurodivergence. Some common behaviours relating to this include challenges in focusing, forgetfulness, restlessness and fidgeting and more.  There are three different subtypes of ADHD including predominantly inattentive presentation, predominantly hyperactive-impulsive presentation and combined presentation. It is also important to recognise that not everyone presents the same and an ADHD diagnosis is not static, as presentations can change over time.</p>
                <p>How do they work at Hashworth Psychology Pty Ltd? </p>
                <p>Our ADHD, Cognitive, and Functional assessments involve a battery of varied testing techniques, which are tailored to the individual’s needs and presentations. This assessment process involves a range of interviews, questionaries, clinical observations and formal diagnostics over the course of about 3- 4 separate sessions. Following these sessions, you will be offered a feedback session where you will have a one-on-one opportunity to discuss your results with your psychologist.</p>
                <p>Our ADHD assessments are applicable for children (ages 3 and over) and adults. Children ages 3-6 engage in an amended assessment while individuals ages 6 and older are eligible for Hashworth Psychology Pty Ltd’s full comprehensive ADHD assessment. Hashworth Psychology Pty Ltd accepts private and NDIS funding. Medicare does not fund ADHD assessments for individuals of any age.</p>
                <p>Adults:</p>
                <p>Session 1: Clinical interview (50-60 mins)</br>
                Session 2: Clinical interview (50-60 mins)</br>
                Session 3: Comprehensive testing including cognitive assessment (90-120 mins)</br>
                Interpretation and report writing (4 hours)</br>
                Session 4: feedback session (50-60 mins)</p>
                <p>For both children and adults, Sessions 1, 2, and 3 are direct testing sessions. Following these three sessions, your clinician will gather all of your data and begin interpreting your results to better understand your diagnoses. Your psychologist will then create a comprehensive report for you. This report can be for a range of purposes, including but not limited to, supporting medication, obtaining funding, study accommodations, or supplementing your therapeutic journey. Following this, we will book in your 4th and final session, which is referred to as your feedback session:</p>
                <p>The total cost of the assessment is $1530 and is divided into three installments, which are payable at Sessions 1, 2, and 3.</p>
                <p>To allow for increased accessibility and financial support, payment is due in installments throughout the assessment process and is NOT required in whole prior to commencement.</p>
                
                <b><p>Our Cognitive and Functional assessments are structured depending on individual needs. Please reach out to our lovely admin team to enquire further.</p></b>
                <p>What is the purpose of a formal diagnosis?</p>
                <p>Individuals choose to undergo a formal diagnosis for many reasons including the following:</p>
                <ul>
                    <li>To access funding via the NDIS</li>
                    <li>To better understand themselves and their needs</li>
                    <li>To identify areas that may require support</li>
                    <li>To acquire a better understanding of how to navigate the world</li>
                    <li>To assist in obtaining personalised accommodations with work or study</li>
                    <li>To support education around different neurodivergence</li>
        
                <p>NOTE: If additional testing is warranted for your ADHD assessment, your psychologist will discuss this with you directly. You will have the opportunity to consent to or decline any extra procedures which may incur additional costs.</p>
                <p>Making a decision about getting an assessment done can be overwhelming. We want to make this easier for you. Get in touch with Hashworth Psychology Pty Ltd today</p>
            `,
            img: logo
        },
        {
            title: "Neuro-Affirming Autism Assessments",
            description: `
                <p>What is a cognitive assessment?</p>
                <p>Psychological assessments involve the collecting and analysing of information relating to individuals’ behaviours, abilities and characteristics. This aims to target the diverse needs of clients, ruling out possible diagnosis, establishing possible diagnosis and using this as a tool to tailor therapy needs. Assessments may be designed according to your individualised goals and queries, to better understand how your brain works.</p>
                <p>What is an autism assessment?</p>
                <p>Autism is a form of neurodivergence that can present in many ways. It is understood as differences in the brain, which may impact the way individuals interact, communicate, and behave. Autistic folks often report “masking” is regularly used to navigate the “neurotypical world”. The team at Hashworth Psychology Pty Ltd adopts a neuro-affirming stance, recognising the uniqueness of every individual. We strive to deliver assessments and treatments that are person-centred, avoiding stigma and misconceptions.</p>
                <p>How do they work at Hashworth Psychology Pty Ltd?</p>
                <p>Our neuro-affirming autism assessments are administered by experienced clinical psychologists, who aim to identify strengths and challenges that impact social interactions and navigation of one’s world. A range of evidence-based techniques are used to address any concerns that arise over the course of approximately 3-4 sessions, plus a feedback session where you will have a one on one opportunity to discuss your results with your psychologist.</p>
                <p>Our clinicians have extensive experience working with individuals across a wide range of ages, demographics, gender identities, and mental health comorbidities. We understand that autistic traits can present differently based on gender and are committed to providing affirming support to cisgender, non-binary, transgender, and queer autistic individuals.</p>
                <p>Our autism assessments are applicable for children (ages 3 and over) and adults. Hashworth Psychology Pty Ltd accepts private and NDIS funding. Medicare funding may be possible for individuals under 25 (see below for further details).</p>
                <p>Adults:</p>
                <p>Session 1: Clinical interview (50-60 mins)</br>
                Session 2: Clinical interview and MIGDAS (50-60 mins)</br>
                Session 3: Clinical interview and MIGDAS (50-60 mins)</br>
                Interpretation and report writing (4 hours)</br>
                Session 4: feedback session (50-60 mins)</p>
                <p>Children:</p>
                <p>Session 1: Parent/caregiver only clinical interview (50-60 mins)</br>
                Session 2: Clinical interview with child and parent/caregiver (50-60 mins)</br>
                Session 3: Clinical interview and MIGDAS (50-60 mins)</br>
                Interpretation and report writing (4 hours)</br>
                Session 4: feedback session (50-60 mins)</p>
                <p>Note: for children ages 15-18, the clinical interview with parents/caregivers is highly recommended but is not required. If not possible, children can attend Sessions 1 and 2 on their own.</p>
                <p>For individuals under 25, Medicare funding may be applicable for the assessment and diagnosis for autism. Referrals are required from a paediatrician (MBS item 135) or a psychiatrist (MBS item 289).</p>
                <p>For both children and adults, Sessions 1, 2, and 3 are direct testing sessions. Following these three sessions, your psychologist will gather all of your data and begin interpreting your results to better understand your diagnoses. Your psychologist will then create a comprehensive report for you. This report can be for a range of purposes, including but not limited to, supporting medication, obtaining funding, study accommodations, or supplementing your therapeutic journey. Following this, we will book in your 4th and final session, which is referred to as your feedback session.</p>
                <p>The total cost of the assessment is $1785, significantly lower than standard costs for similar services, ensuring accessibility without compromising quality. This amount is divided into three instalments, which are conveniently payable at Sessions 1, 2, and 3.</p>
                <p>To allow for increased accessibility and financial support, payment is due in instalments throughout the assessment process and is NOT required in whole prior to commencement.</p>
                <p>What is the purpose of a formal diagnosis?</p>
                <p>Individuals choose to undergo a formal diagnosis for many reasons including the following:</p>
                <ul>
                    <li>To access funding via the NDIS</li>
                    <li>To better understand themselves and their needs</li>
                    <li>To identify areas that may require support</li>
                    <li>To acquire a better understanding of how to navigate the world</li>
                    <li>To assist in obtaining personalised accommodations with work or study</li>
                    <li>To support education around different neurodivergence</li>
                </ul>
                <p>NOTE: If additional testing is warranted for your autism assessment, your psychologist will discuss this with you directly. You will have the opportunity to consent to or decline any extra procedures which may incur additional costs.</p>
                <p>Making a decision about getting an assessment done can be overwhelming. We want to make this easier for you. Get in touch with Hashworth Psychology Pty Ltd today.</p>            
            `,
            img: logo
        },
        {
            title: "Neurodiversity-Affirming Therapy",
            description: `
                <p>At Hashworth Psychology, we are dedicated to providing neurodiversity-affirming therapy that celebrates and respects the unique strengths and experiences of neurodivergent individuals. Our approach is rooted in understanding and supporting autistic individuals, ADHDers, and other neurodivergent people in a safe, compassionate, and empowering environment.</p>
                <p>We work collaboratively with clients to address challenges such as masking, burnout, sensory sensitivities, and self-advocacy while promoting self-acceptance and personal growth. Our therapists focus on helping you develop strategies to navigate the world in ways that honour your individuality, without striving to “fix” or change who you are.</p>
                <p>Whether you're seeking support for mental health, personal development, or navigating neurodivergent experiences in a neurotypical world, we are here to guide and support you. For more information or to book an appointment, please contact our team.</p>
            `,
            img: logo
        },
        {
            title: "Intrusive Thoughts, Obsessions and Compulsions",
            description: `
            <p>Intrusive and unwanted thoughts are thoughts that appear in your mind and often become stuck. Everyone has experienced an intrusive thought at some point in their life and that’s normal. When these thoughts continue to come up without us wanting them to and they begin to cause distress, shame, or guilt, that’s a sign that these intrusive thoughts are taking over. </p>
            <p>The content of intrusive thoughts may be violent, disturbing, sexual, or religious. They may be about ourselves or others. They may also present in the form of negative self-talk and self-hatred. </p>
            <p>Obsessions, compulsions, or a diagnosis of obsessive-compulsive disorder (OCD), may sometimes stem from intrusive thoughts. Obsessions are recurrent, persistent, intrusive, and unwanted thoughts, images, or urges that cause us anxiety or distress. They may take up a great deal of our mental energy and may even make us feel fearful when they do arise. </p>
            <p>Compulsions are the actual behaviours or mental acts that we may do in response to an obsessive or intrusive thought. These compulsions are meant to reduce our anxiety, fear, shame, guilt, paranoia, or other negative emotions that are related to our thoughts. Compulsions may also be used as a way to try and prevent something bad from happening. They tend to help relieve our negative emotions momentarily, but unfortunately, it doesn’t take long for the obsessive or intrusive thought to re-enter our minds. We then may find ourselves repeating the compulsive behaviour and even doing it before the thought enters, as a way to prevent it. </p>
            <p>Some examples of compulsions are: </p>
            <ul>
                <li>Excessive cleaning</li>
                <li>Hand washing</li>
                <li>Repeatedly checking doors, locks, and appliances</li>
                <li>Rituals (physical or mental) to ward off contact with superstitious objects</li>
                <li>Re-arranging items or objects in specific ways</li>
                <li>Using chants or mantras to prevent bad things from happening</li>
                <li>Hoarding unnecessary items</li>
                <li>Counting or knocking, to prevent specific outcomes</li>
                <li>Skin picking or hair pulling</li>
                <li>Walking or stepping in a certain way that feels outside of your control</li>
            </ul>
            `,
            img: logo
        },
        {
            title: "Anxiety and Depression",
            description: `
                <p>All of us experience anxiety at some point in our lives. Our brains and bodies are hardwired to alert us when we are in dangerous situations, which trigger a fight, flight, or freeze response. We need this to help us get out of danger and head towards safety. Sometimes, our brains alert us to situations which aren’t actually dangerous and our body responds with anxiety, though we are relatively safe. Oftentimes too, these feelings of stress and anxiety don’t go away when the trigger is removed and can stay with us for days, weeks, months, or even years.</p>
                <p>Anxiety and depression are the most common mental health disorders throughout Australia. Anxiety can impact us in a range of ways and can differ from person to person. Anxiety can manifest physically through panic attacks, fatigue, insomnia, digestive concerns, headaches, nightmares, chest pains, quick, shallow, or short breaths, restlessness, or muscle tension. Psychologically, anxiety can present as excessive fear, worry, catastrophising, obsessive or intrusive thoughts, feeling as if one is “going crazy”, mind blanks, anticipating the worst outcome, or negative thoughts of ourselves or the world. We can sometimes notice our anxiety through our behaviours, for example, if we are avoiding situations, people, or triggers, coping with substances, withdrawing from others, having difficulties leaving the house, or becoming easily irritable.</p>
                <p>In addition, depression and mood disorders are extremely common throughout the world. That being said, that doesn’t make them any easier to deal with. Every one of us has had times where we feel sad, moody, or low, but those of us with depression can experience this for days, weeks, months, or years at a time.</p>
                <p>To meet criteria for a depressive episode, you may experience some of the following:</p>
                <ul>
                    <li>Feeling sad, down, irritable, or miserable for most days</li>
                    <li>Loss of interest or pleasure in activities that you used to enjoy</li>
                    <li>Changes in your appetite or weight</li>
                    <li>Difficulties sleeping or over-sleeping</li>
                    <li>Feeling physically slower than your usual or increased restlessness</li>
                    <li>Difficulties thinking or concentrating</li>
                    <li>Indecisiveness</li>
                    <li>Thoughts of dying, self-harming, or escaping your reality</li>
                </ul>
                <p>If you need support understanding your anxiety and/or depression symptoms, a qualified mental health professional can help! For some, it may feel like we have always been anxious or depressed. For others, these signs and symptoms may be rather recent. They may be in response to a recent situation or trigger or they may have begun without any explanations. Anxiety and depression can impact us in a number of ways –we may notice changes in our behaviour, our emotions, and an emergence of physical symptoms.</p>
            `,
            img: logo
        },
        {
            title: "Clinical Supervision",
            description: `
                <p>Dr Talia Hashworth is a clinical supervisor approved by the Psychology Board of Australia, who can provide supervision to meet a range of needs. Clinical supervision is essential for facilitating the training of practitioners working towards becoming registered psychologists, as well as maintaining the knowledge of fully registered or endorsed psychologists. Engaging in regular supervision is considered a crucial part of psychologists’ continuing professional development (CPD).</p>
                <p>Dr Hashworth is able to tailor supervision to meet the specific needs of her supervisees, with a focus on skills training, insight building, self-reflection, and managing the therapeutic relationship. Supervision is offered individually via Zoom.</p>
                <p>Dr Hashworth offers supervision for:</p>
                <ul>
                    <li>Clinicians interested in upskilling and specialising in the below areas:</li>
                    <ul>
                        <li>PTSD and complex trauma (C-PTSD)</li>
                        <li>Borderline personality disorder</li>
                        <li>The LGBTQIA+ community</li>
                    </ul>
                    <li>Registration as a psychologist (including 4+2, 5+1, or clinical registrars)</li>
                    <li>Endorsement as a clinical psychologist</li>
                    <li>Other mental health and allied health professionals including but not limited to Accredited Mental Health Social Workers, Counsellors, and Psychotherapists</li>
                    <li>Ongoing professional development activities and peer supervision requirements</li>
                    <li>Mental health practitioners wishing to further develop their skills in:</li>
                    <ul>
                        <li>Psychological assessment and treatment</li>
                        <li>Eye Movement Desensitisation Reprocessing (EMDR) Therapy</li>
                        <li>Schema Therapy</li>
                        <li>Cognitive Behavioural Therapy (CBT)</li>
                        <li>Dialectical Behavioural Therapy (DBT)</li>
                        <li>Acceptance and Commitment Therapy (ACT)</li>
                        <li>ADHD assessments</li>
                        <li>Autism assessments</li>
                    </ul>
                    <li>Note: Dr Hashworth does not supervise the following areas: eating disorders, drug and alcohol addictions, or children under 8</li>
                </ul>
            `,
            img: logo
        }
    ],
    subheading: "What we offer",
    sessionsDescription: "We provide a range of services to help suit your needs. Feel free to get in contact with us to discuss the best fit for you.",
    sections: [
        {
            heading: "Individual Psychology Session",
            description: `
                <p>Effective evidence-based therapy for adolescents and adults that can help with various mental health difficulties. Sessions can be held in Wollongong NSW or remotely via telehealth. Please see the details below to learn more about who we generally work with.</p>
                <p>In these sessions, we use a range of validated and evidence-based approaches, including, but not limited to:</p>
                <ul>
                    <li>Eye Movement Desensitisation Reprocessing (EMDR) Therapy</li>
                    <li>Dialectical Behavioural Therapy (DBT)</li>
                    <li>Schema Therapy</li>
                    <li>Cognitive Behavioural Therapy (CBT)</li>
                    <li>Trauma-Focused CBT</li>
                    <li>Acceptance & Commitment Therapy (ACT)</li>
                    <li>Motivational Interviewing (MI)</li>
                    <li>Feedback Informed Treatment (FIT)</li>
                    <li>Exposure Therapy and Response Prevention (ERP)</li>
                    <li>Family Therapy</li>
                    <li>Couples Therapy</li>
                </ul>
            `
        },
        {
            heading: "Extended EMDR Session",
            description: `
                <p>Extended EMDR sessions are an option for clients undergoing EMDR treatment. These sessions are 90 minutes and allow for more extensive and thorough EMDR processing. Sessions can be held in Wollongong NSW or remotely via telehealth. Please reach out to your clinician to discuss whether this is a suitable option for you.</p>
            `
        },
        {
            heading: "Dialectical Behaviour Therapy Group Program",
            description: `
                <p>Hashworth Psychology regularly runs either 5-week or 10-week Dialectical Behaviour Therapy (DBT) group programs for both adolescents and adults. We welcome individuals with or without a diagnosis of borderline personality disorder. Run by trained psychologists, we incorporate evidence-based strategies tailored to individual needs. The program alternates between running on-site in Wollongong NSW and online. If interested, please reach out to enquire about our next start date.</p>
            `
        },
        {
            heading: "ADHD and Autism Assessments",
            description: `
            <p>Hashworth Psychology conducts comprehensive ADHD and autism assessments for children (ages 3+) and adults. Our assessments span multiple testing sessions, including a feedback session, and a detailed report. Psychological assessments involve the collecting and analysing of information relating to individuals’ behaviours, abilities and characteristics. This aims to target the diverse needs of clients, ruling out possible diagnosis, establishing possible diagnosis and using this as a tool to tailor therapy needs. Assessments can be conducted in Wollongong NSW or remotely via telehealth. To learn more, click on the boxes above or reach out to our wonderful admin team.</p>
            `
        },
        {
            heading: "How Can Hashworth Psychology Help Me?",
            description: `
                <p>Servicing the wider Illawarra and Sydney regions, we understand that what we feel and what we experience generally do not fit in a box.</p>
                <p>If you are experiencing any of the below difficulties, we encourage you to reach out:</p>
                <ul>
                    <li>Concerns related to sexual or gender identity</li>
                    <li>Challenges navigating and understanding your neurodivergence</li>
                    <li>Depression or low mood</li>
                    <li>Self harm, self-defeating behaviours, or suicidal thoughts</li>
                    <li>Anxiety</li>
                    <li>Stress</li>
                    <li>Panic attacks</li>
                    <li>Grief and loss</li>
                    <li>Childhood or adulthood traumatic experiences</li>
                    <li>Personality disorders</li>
                    <li>Unstable or difficult relationships</li>
                    <li>Impulsivity</li>
                    <li>Bipolar disorder</li>
                    <li>Substance use and addictions</li>
                    <li>OCD</li>
                    <li>Phobias</li>
                    <li>Uncertainty about who you are as a person and who you aspire to be</li>
                </ul>
            `
        }
    ]
}