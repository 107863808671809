import React from "react";

import "./LandingPage.css";

class landingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const navigateToIllawara = () => {
            this.props.history.push("/home");
            window.scrollTo(0, 0);
        };

        return (
            <>
                <div className="container">
                    <div className="boxLanding box1">
                        <div className="boxContent">
                            <h1>Hashworth Psychology’s Diversity Clinic – Sydney</h1>
                            <h3>**Coming Soon, stay tuned for updates**</h3>
                        </div>
                    </div>
                    <div className="boxLanding box2" onClick={navigateToIllawara}>
                        <h1 className="boxContent">Hashworth Psychology’s Diversity Clinic – The Illawarra</h1>
                    </div>
                    <div className="boxLanding box3">
                        <div className="boxContent">
                            <h1>Hashworth Psychology’s Trauma and Diversity Academy</h1>
                            <h3>**Coming Soon, stay tuned for updates**</h3>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default landingPage;